import { ValidSessionRedirectRoute } from '@ferreri/minato-auth-react';
import { PageLayoutFooter } from '@ferreri/prever-component-lib';

import { SkeletonHomePage } from '../components/skeletons/SkeletonHomePage';
import { Hub } from '../modules/hub/Hub';
import { HUB_HOME } from '../modules/routes';
import { PAGE_TITLE } from '../utils/pageTitle';

export default function HubPage() {
  return (
    <ValidSessionRedirectRoute
      redirectUrl={HUB_HOME}
      loadingComponent={<SkeletonHomePage />}
    >
      <PageLayoutFooter
        title={PAGE_TITLE.landingPage}
        removeBannerImpersonated={true}
      >
        <Hub />
      </PageLayoutFooter>
    </ValidSessionRedirectRoute>
  );
}
