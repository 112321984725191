import { SkeletonHeaderHome, Content } from '@ferreri/prever-component-lib';

import { SkeletonSection } from './SkeletonSection';
import { PageLayoutMenuFooter } from '../PageLayoutMenuFooter';

export const SkeletonHomePage = () => {
  return (
    <PageLayoutMenuFooter>
      <Content>
        <SkeletonHeaderHome />
        <SkeletonSection numberOfCards={3} />
        <SkeletonSection numberOfCards={3} />
      </Content>
    </PageLayoutMenuFooter>
  );
};
