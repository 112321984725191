import {
  MenuBarBase,
  MenuLinkPrimary,
  PersonFillMediumIcon,
  PersonOutlineMediumIcon,
  ProductAngeplusFillMediumIcon,
  ProductAngeplusOutlineMediumIcon,
} from '@ferreri/prever-component-lib';
import { ReactNode } from 'react';

export type HubMenuProps = {
  children?: ReactNode;
};

export const HubMenu = (props: HubMenuProps) => (
  <MenuBarBase subMenu={props.children}>
    <MenuLinkPrimary
      label={'Angeplus'}
      href={`/hub`}
      icon={<ProductAngeplusOutlineMediumIcon />}
      activeIcon={<ProductAngeplusFillMediumIcon />}
    />
    <MenuLinkPrimary
      label={'Perfil'}
      href={`/accounts`}
      icon={<PersonOutlineMediumIcon />}
      activeIcon={<PersonFillMediumIcon />}
    />
  </MenuBarBase>
);
