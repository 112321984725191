import styled from '@emotion/styled';
import {
  Skeleton,
  SkeletonText,
  Section,
  token,
  createMediaQuery,
} from '@ferreri/prever-component-lib';

type SkeletonSectionProps = {
  numberOfCards?: number;
};

export const SkeletonSection = (props: SkeletonSectionProps) => {
  const numberOfCards = props.numberOfCards || 2;
  const cardsArray = Array.from({ length: numberOfCards });

  return (
    <Section>
      <SkeletonText width={200} textSize="lg" />
      <SkeletonText width={330} />
      <GridContainer>
        {cardsArray.map((_, index) => {
          return <Skeleton width={155} height={148} key={index} />;
        })}
      </GridContainer>
    </Section>
  );
};

const GridContainer = styled.div`
  gap: ${token.spacing.spacingSmall};
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${createMediaQuery({
    tablet: {
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      alignItems: 'center',
    },
  })}
`;
