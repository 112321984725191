import { ReactNode } from 'react';
import { PageLayoutMenuFooter as PageLayoutMenuFooterLib } from '@ferreri/prever-component-lib';

import { HubMenu } from './HubMenu';

type MenuDefaultProps = {
  pageTitle?: string;
  children: ReactNode;
};

export const PageLayoutMenuFooter = (props: MenuDefaultProps) => {
  return (
    <PageLayoutMenuFooterLib title={props.pageTitle} sideMenu={<HubMenu />}>
      {props.children}
    </PageLayoutMenuFooterLib>
  );
};
